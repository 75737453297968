@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  transition: all 0.5s;
  font-family: 'Poppins', sans-serif;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* input task and date */
.box {
  padding: 50px;
  display: flex;
  justify-content: center;
}

.box form {
  width: 700px;
  height: fit-content;
  box-shadow: 0px 0px 15px black;
  padding: 30px 30px 10px;
  border-radius: 20px;
}

.box form .addTask {
  display: block;
  margin: 20px 0;
  border: 1px solid rgba(0, 0, 0, 0.4);
  outline: none;
  background-color: transparent;
  color: black;
  width: 70%;
  padding: 5px 0;
  padding-left: 10px;
  font-size: 16px;
  font-weight: 500;
}

.box form .addTask:focus {
  border-color: black;
}

.box form .date {
  display: inline-block;
  margin-left: 32px;
  font-size: 14px;
  font-weight: 500;
}

.box form .submit {
  margin: 40px auto 20px;
  display: block;
  background-color: black;
  border: 1px solid black;
  padding: 12px 24px;
  color: white;
  font-size: 17px;
  font-weight: 400;
  cursor: pointer;
  position: relative;
}

.box form .submit:hover {
  background-color: white;
  color: black;
}

.box form label {
  color: black;
  font-size: 20px;
  font-weight: 400;
  display: inline-block;
}


/* list box */
.listBox {
  width: 1140px;
  padding: 20px;
  background-color: white;
  border: 1px solid black;
  border-radius: 20px;
  margin: 20px 0;
}

.listBox .content {
  width: 70%;
}

.listBox input,
.email input,
button {
  border-radius: 10px;
  margin: 0 10px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 500;
  background-color: white;
  border: 1px solid black;
  cursor: pointer;
}

.listBox input:hover,
.email input:hover,
button:hover {
  background-color: black;
  color: white;
}

button {
  display: block;
  margin: 0 auto;
}
